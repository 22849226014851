import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="validation"
export default class extends Controller {
  static targets = ["input"];
  connect() {
    // alert(this.numberTarget.value);
    // alert("Hello, Stimulus!");
  }

  validate(event) {
    // alert("aaa");
    // Your validation logic here
    if (this.element.value.length < 5) {
      // Example: Display an error message if the this.element value is too short
      this.element.classList.add("border-red-500"); // Add a red border to the this.element
      // You can also display an error message here
    } else {
      this.element.classList.remove("border-red-500"); // Remove the red border if validation passes
    }
  }
}
